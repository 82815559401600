<template>
  <el-pagination
    background
    class="pagination-wrapper"
    @current-change="handleCurrentChange"
    :current-page="currentPage"
    :page-size="pageSize"
    :total="total"
    :layout="layout"
  >
    <slot></slot>
  </el-pagination>
</template>

<script>
export default {
  props: {
    currentPage: {
      type: Number,
      default: 1
    },
    pageSize: {
      type: Number,
      default: 15
    },
    total: {
      type: Number,
      default: 0
    },
    layout: {
      type: String,
      default: "total, prev, pager, next"
    }
  },
  methods: {
    handleCurrentChange(val) {
      this.$emit("goToPage", val);
    }
  }
};
</script>
