<template>
  <div class="brain-popover" v-if="showPopover">
    <div class="popover-mask"></div>
    <div class="popover-content">
      <div class="popover-head">
        <span class="popover-title">{{ popoverTitle }}</span>
      </div>
      <div class="popover-body tags-popover">
        <el-form :model="tagObj" :rules="rules" ref="modelForm" label-width="140px">
          <el-form-item :label="$t('tags.tag_name') + '：'" prop="name">
            <el-input v-model="tagObj.name"></el-input>
          </el-form-item>
          <el-form-item :label="$t('tags.tag_desc') + '：'" prop="description">
            <el-input type="textarea" :rows="3" v-model="tagObj.description"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="popover-footer text-right">
        <button
          type="button"
          class="btn btn-grey btn-sm ml10"
          @click.stop="closePopover"
        >
          {{ $t("cancel") }}
        </button>
        <button
          type="button"
          class="btn btn-primary btn-sm ml10"
          @click.stop="submitTag('modelForm')"
        >
          {{ $t("save") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tagObj: {
      type: Object,
      default: function() {
        return {};
      }
    },
    showPopover: {
      type: Boolean,
      default: false
    },
    popoverTitle: {
      type: String,
      default: ""
    }
  },
  computed:{
    rules() {
      return {
        name: [
          { required: true, message: this.$t("validate.tag_name"), trigger: 'change' }
        ],
      }
    }
  },
  methods: {
    submitTag(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$emit("submitTag", this.tagObj);
        } else {
          return false;
        }
      });
    },
    closePopover() {
      this.$emit("closePopover");
    }
  }
};
</script>

<style lang="less">
.tags-popover label{
  margin-bottom: 0;
}
</style>
