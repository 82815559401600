<template>
  <div class="data-manage flexible">
    <div class="main-content">
      <div class="page-title clearfix">
        <div class="float-left">
          <span>{{ $t("nav.tag_manage") }}</span>
          <span class="total-num">{{ totalCount }}</span>
        </div>
        <div class="float-right">
          <button
            type="button"
            class="btn btn-danger btn-sm"
            @click="showAddPopover"
          >
            {{ $t("tags.add_tag") }}
          </button>
        </div>
      </div>
      <div class="data-content">
        <div class="search-wrapper">
          <span class="search-btn" @click.stop="getDataList">{{
            $t("search")
          }}</span>
          <el-form :inline="true" class="search-form" size="small">
            <el-form-item>
              <el-input v-model="search" clearable placeholder=" "></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="tabel-content">
          <el-table
            ref="multipleTable"
            :data="dataList"
            :empty-text="$t('no_data')"
            style="width: 100%"
            header-row-class-name="thead-dark"
          >
            <el-table-column
              prop="name"
              :label="$t('tags.tag_name')"
            ></el-table-column>
            <el-table-column
              prop="description"
              :label="$t('tags.tag_desc')"
            ></el-table-column>
            <el-table-column :label="$t('operate')" align="center" width="150">
              <template v-slot="scope">
                <span
                  class="cursor text-primary mr10"
                  @click="showEditTag(scope.row)"
                  >{{ $t("edit") }}</span
                >
                <span
                  class="cursor text-danger"
                  @click="handleDeleteTag(scope.row.id)"
                  >{{ $t("delete") }}</span
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="clearfix mt-3 mb-3">
        <div class="float-left">
          <pagination
            v-if="totalCount > 0"
            :pageSize="pageSize"
            :layout="layout"
            :currentPage="currentPage"
            :total="totalCount"
            @goToPage="handleCurrentChange"
          />
        </div>
      </div>
    </div>
    <edit-model
      :showPopover="showPopover"
      :tagObj="tag_obj"
      :popoverTitle="popoverTitle"
      @closePopover="closePopover"
      @submitTag="submitTag"
    >
    </edit-model>
  </div>
</template>

<script>
import editModel from "../../components/tags/editModel";
import { tagsRequest } from "../../api/api";
import { MessageBox, Loading } from "element-ui";
import pagination from "../../components/pager";
import { paginationMixin } from "../../utils/mixin";

export default {
  mixins: [paginationMixin],
  components: {
    editModel,
    pagination,
  },
  data() {
    return {
      search: "",
      dataList: new Array(),
      tag_obj: {},
      showPopover: false,
      popoverType: "",
      popoverTitle: "",
      pageSize: 15,
      currentPage: 1,
      totalCount: 0,
    };
  },
  methods: {
    async getDataList() {
      if (this.search.toString() == "0") {
        this.dataList = [];
        this.totalCount = 0;
        return;
      }
      let loadingInstance = Loading.service({
        background: "rgba(0, 0, 0, 0.7)",
      });
      let params = {
        request: "list",
        userid: this.$store.state.userid,
        search: this.search,
        limit: this.pageSize,
        offset: this.currentPage - 1,
      };

      try {
        let res = await tagsRequest(params);
        if (res.status === 200) {
          this.dataList = res.data.length > 0 ? res.data : [];
          this.totalCount = res.total;
        } else {
          this.dataList = [];
          this.totalCount = 0;
        }
        loadingInstance.close();
      } catch (error) {
        loadingInstance.close();
        this.$helper.errNotify(error.message);
      }
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getDataList();
    },
    showAddPopover() {
      this.popoverType = "add";
      this.popoverTitle = this.$t("tags.add_tag");
      this.showPopover = true;
    },
    showEditTag(tag) {
      let editTag = {
        name: tag.name,
        description: tag.description,
        currentid: tag.id,
      };
      this.popoverType = "edit";
      this.popoverTitle = this.$t("tags.edit_tag");
      this.showPopover = true;
      this.tag_obj = editTag;
    },
    closePopover() {
      this.tag_obj = {};
      this.showPopover = false;
    },
    async submitTag(tag) {
      let loadingInstance = Loading.service({
        background: "rgba(0, 0, 0, 0.7)",
      });

      let request =
        this.popoverType === "add"
          ? "insert"
          : this.popoverType === "edit"
          ? "update"
          : "";
      let params = {
        request: request,
        userid: this.$store.state.userid,
        ...tag,
      };
      try {
        let res = await tagsRequest(params);
        if (res.status === 200) {
          this.$helper.successNotify(res.message);
          this.getDataList();
          this.closePopover();
        } else {
          this.$helper.errNotify(res.message);
        }
        loadingInstance.close();
      } catch (error) {
        loadingInstance.close();
        this.$helper.errNotify(error.message);
      }
    },
    handleDeleteTag(id) {
      MessageBox.confirm(this.$t("notice.p_confirm"), this.$t("notice.tips"), {
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        type: "warning",
      })
        .then(() => {
          this.deleteTag(id);
        })
        .catch(() => {});
    },
    async deleteTag(id) {
      let loadingInstance = Loading.service({
        background: "rgba(0, 0, 0, 0.7)",
      });

      let params = {
        request: "remove",
        userid: this.$store.state.userid,
        currentid: id,
      };
      try {
        let res = await tagsRequest(params);
        if (res.status === 200) {
          this.$helper.successNotify(res.message);
          this.getDataList();
        } else {
          this.$helper.errNotify(res.message);
        }
        loadingInstance.close();
      } catch (error) {
        loadingInstance.close();
        this.$helper.errNotify(error.message);
      }
    },
  },
  created() {
    this.getDataList();
  },
};
</script>

<style lang="less">
.table {
  border-bottom: 1px solid #dee2e6;
}
.data-manage {
  .main-content {
    transition: 0.3s ease-in;
  }
  .main-content.active {
    width: calc(~"100% - 220px");
    // transform: translate(-220px);
    // margin-right: 220px;
  }
  .el-checkbox {
    margin-bottom: 0;
    // color: #fff;
  }
  .el-table .cell {
    overflow: visible;
  }
  .el-table {
    overflow: visible;
  }
  .el-table__body-wrapper {
    overflow: visible !important;
  }
}
.data-manager-popover {
  .popover-content {
    margin: 120px auto 0;
    width: 700px;
    background: #fff;
  }
}
.dialog-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2003;
  overflow: auto;
  margin: 0;
  background: rgba(0, 0, 0, 0.6);
  .dialog-content {
    position: relative;
    margin: 15vh auto;
    border-radius: 3px;
    width: 600px;
    background: #fff;
  }
  .dialog-header {
    padding: 20px 10px;
    border-bottom: 1px solid #dee2e6;
  }
  .dialog-title {
    line-height: 24px;
    font-size: 18px;
    color: #303133;
  }
  .dialog-headerbtn {
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 0;
    background: 0 0;
    border: none;
    outline: 0;
    cursor: pointer;
    font-size: 16px;
  }
  .dialog-body {
    padding: 30px 0;
    text-align: center;
  }
}
</style>
