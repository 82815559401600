export const paginationMixin = {
  data() {
    return {
      page: 1,
      pageSize: 10,
      totalCount: 0,
      currentPage: 1,
      layout: "pager"
    };
  },
  methods: {
    // 分页请求
    handleCurrentChange(val) {
      this.currentPage = val;
      const params = {
        page: this.currentPage,
        pageSize: this.pageSize
      };
      this.searchData(params);
    },
    // 切换每页显示多少条数据
    handleSizeChange(val) {
      this.pageSize = val;
      const params = {
        page: this.currentPage,
        pageSize: this.pageSize
      };
      this.searchData(params);
    }
  }
};
